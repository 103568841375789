import React from 'react'
import {ReactComponent as Logo} from '../../assets/Logo01.svg'
const Footer = () => {
    return(
        <div className='py-8 mx-12'>
        <div className='flex flex-row items-center justify-center'>
            <div className='flex flex-col items-center'>
            <Logo className=' w-20'/>
          
            <div className='flex space-x-2 items-center relative mt-3'>
                <a href='https://web.facebook.com/LIPA.NA.MPESA.MPAY'><img className='object-cover object-center w-6' src={require("../../assets/icons8_linkedin_circled_480px.png")} alt="linkedin"/></a>
                <a href='https://web.facebook.com/LIPA.NA.MPESA.MPAY'><img className='object-cover object-center w-6' src={require("../../assets/icons8_facebook_90px.png")} alt="facebook"/></a>
                <a href='https://web.facebook.com/LIPA.NA.MPESA.MPAY'><img className='object-cover object-center w-5' src={require("../../assets/icons8_instagram_old_96px.png")} alt="instagram"/></a>
                <a href='https://web.facebook.com/LIPA.NA.MPESA.MPAY'><img className='object-cover object-center w-6' src={require("../../assets/icons8_twitter_circled_90px.png")} alt="twitter"/></a>
            </div>

            <span className='  text-center text-sm font-Roboto font-medium opacity-95 mt-2'>© 2022 M-PAY · All Rights Reserved · A Product of Pioneer FSA </span>
            </div>
            
        </div>
        </div>
    )
}
export default Footer;
