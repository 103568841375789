import React from "react";
import { GoogleApiWrapper, Map, Marker, InfoWindow } from "google-maps-react";

class ContactUs extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      mapLoaded: false,
      activeMarker: {},
      selectedPlace: {}
    };
  }
  onMarkerClick = (props, marker, e) =>{

    console.log(props);
    console.log(marker);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  onInfoWindowClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  }
  handleMapLoaded = () => {
    this.setState({
      mapLoaded: true
    });
  }
  onMarkerMounted = (ref) => {
    console.log(ref);
    this.onMarkerClick(ref.props, ref.marker,ref);
  }
  render() {
    const {google} = this.props;
    return (
      <div id="contact" className="flex flex-col items-center py-4 ">
        <div className="relative">
          <h2 className="font-Rubik text-2xl">Contact Us</h2>
          <span className="border-b-2 absolute px-10 py-1 inset-x-12 inset-y-2 border-black"></span>
        </div>

        <div className="max-w-4xl mt-3 flex flex-col items-center px-6">
          <p className=" text-justify md:text-center font-Roboto font-medium opacity-90">
            Need help or have a question? Feel free to chat with us. We are
            available 24/7 to address issues you may have. Chat with us directly
            from our Live module. You also are free to call or email us from the
            information given.
          </p>
          <div className="flex flex-col items-center space-y-2 md:space-y-0 md:flex-row md:space-x-3 mt-4 font-Roboto font-medium opacity-80 text-sm">
            <span>Upper Hill Shelter Afrique centre, Nairobi, Kenya</span>
            <span>info@m-pay.co.ke</span>
            <span>(+254) 709-058-058</span>
            <span>(+254) 710-914-969</span>
          </div>
        </div>
        <Map
          google={google}
          zoom={11}
          onReady={this.handleMapLoaded}
          initialCenter={{
            lat: -1.299431,
            lng: 36.815473,
          }}
          className=" relative w-full h-96 mt-5"
        >
        {
          this.state.mapLoaded && (
          <Marker
            onClick={this.onMarkerClick}      
            label="M"
            ref={this.onMarkerMounted}
          />

          )}
       
          <InfoWindow onClose={this.onInfoWindowClose}  marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            {console.log(this.state.showingInfoWindow)}
            <div className=" max-w-xs">
              <h3 className="font-Poppins  text-lg">
                m-pay offices
              </h3>
              <p className=" font-Roboto font-medium ">
              Upper Hill Shelter Afrique centre, Nairobi, Kenya
              2nd Floor. Prudential Group.
              </p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyACB_7w3C_1iEiyd5Tinp9xVqJOqaXeb8g",
})(ContactUs);
