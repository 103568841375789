import React from "react";
import { Button, Link } from "react-scroll";
import { withRouter } from "./withRouter";
import { ReactComponent as Logo } from "../../assets/Logo01.svg";

class Navigation extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isOpen: false
    }
  }
    render(){
      const {className,navigate} = this.props;

      return (
        <div className={`flex flex-col justify-between lg:items-center lg:flex-row px-8 lg:px-24 py-3 lg:py-2 backdrop-blur-sm bg-white/30 ${className} w-full z-50`}>
          <div className="flex flex-row items-center justify-between  ">
            <Link to="header" spy={true} smooth={true}>
              <img src={require("../../assets/logo11.png")} className=" mt-1 w-24 md:w-32 " />
            </Link>

            <button
              data-collapse-toggle="menu"
              className="inline-flex bg-transparent items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden "
              aria-controls="mobile-menu-2"
              aria-expanded="false"
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className={`${this.state.isOpen?'block':'hidden'} md:mt-0 lg:block`} id="menu">
            <div className=" flex flex-col flex-nowrap lg:flex-row lg:space-x-9 space-y-1 lg:space-y-0 font-Rubik text-base">
              <Link onClick={()=>
               this.setState({ isOpen: !this.state.isOpen })}
               className="text-black" to="home" smooth={true} spy={true}>
                Home
              </Link>
              <Link>
                <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" className="flex justify-between items-center text-black p-0 w-full">Products<svg class="ml-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></button>
                <div id="dropdownNavbar" class="hidden z-10 w-44  text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                    <ul class="py-1 font-Rubik" aria-labelledby="dropdownLargeButton">
                      <li onClick={
                        ()=>{
                          window.location.href = "https://play.google.com/store/apps/details?id=com.pioneerfsa.mpay";
                        }
                      }>
                        <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"> M-Pay App</a>
                      </li>
                      <li onClick={
                        ()=>{
                          window.location.href = "https://register.m-pay.co.ke/?r=lnm ";
                        }
                      }>
                        <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Get Lipa Na M-pesa</a>
                      </li>
                      <li onClick={
                        ()=>{
                          window.location.href = "https://loans.m-pay.co.ke/";
                      }}>
                        <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">Loans</a>
                      </li>
                      <li onClick={
                        ()=>{
                         window.location.href = "https://docs2.m-pay.co.ke/";                  
                        }            
                      }>
                        <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">C2B API's</a>
                      </li>
                    </ul>
                </div>
              </Link>
              <Link onClick={
                ()=>this.setState({ isOpen: !this.state.isOpen })
              } className="text-black" to="services" smooth={true} offset={-70} spy={true}>
                Services
              </Link>

              <Link onClick={
                ()=>this.setState({ isOpen: !this.state.isOpen })
              }  data-collapse-toggle="menu" className="text-black" to="about" smooth={true} offset={-70} spy={true}>
                About us
              </Link>
              
              <Link onClick={
                ()=>this.setState({ isOpen: !this.state.isOpen })
              } className="text-black" to="contact" offset={-70} smooth={true} spy={true}>
                Contact us
              </Link>
          
            
            </div>
          </div>
        </div>
      );
    }
};
export default withRouter(Navigation);