import React from "react";
import "./App.css";
import "./animxyz.scss";
import "./antd.less";
import Navigation from "./components/navigation/navigation.component";
import HeaderCarousel from "./components/header-carousel/header-carousel.component";
import M_Services from "./components/m-services/m-services.component";
import About from "./components/about/about.component";
import Testimonials from "./components/testimonials/testimonials.component";
import Partner from "./components/partner-ribbon/partner.component";
import ContactUs from "./components/contact/contact.component";
import Footer from "./components/footer/footer.component";
import TawkTo from "tawkto-react";

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  handleServicesContainerAnimation = () => {
    const servicesContainer = document.getElementById("s-container");
    var position = servicesContainer.getBoundingClientRect();

    if (
      position.top >= -window.innerHeight / 2 &&
      position.top <= window.innerHeight * 0.8
    ) {
      for (var i = 0; i < servicesContainer.children.length; i++) {
        if (!servicesContainer.children[i].classList.contains("xyz-in")) {
          servicesContainer.children[i].classList.add("xyz-in");
          servicesContainer.children[i].classList.remove("xyz-out");
        }
      }
    } else {
      for (var i = 0; i < servicesContainer.children.length; i++) {
        if (servicesContainer.children[i].classList.contains("xyz-in")) {
          servicesContainer.children[i].classList.add("xyz-out");
          servicesContainer.children[i].classList.remove("xyz-in");
        }
      }
    }
  };
  handleAboutContainerAnimation = () => {
    const aboutHSection = document.getElementById("about-header");
    const aboutCSection=document.getElementById("about-content");
    var position = aboutHSection.getBoundingClientRect();
    if(position.top >= -window.innerHeight / 2 && position.top <= window.innerHeight * 0.8){
      for(var i = 0; i < aboutHSection.children.length; i++){
        if(!aboutHSection.children[i].classList.contains("xyz-in")){
          aboutHSection.children[i].classList.add("xyz-in");
          aboutHSection.children[i].classList.remove("xyz-out");
        }
      }
      for(var i = 0; i < aboutCSection.children.length; i++){
        if(!aboutCSection.children[i].classList.contains("xyz-in")){
          aboutCSection.children[i].classList.add("xyz-in");
          aboutCSection.children[i].classList.remove("xyz-out");
        }
      }
    }
    else{
      for(var i = 0; i < aboutHSection.children.length; i++){
        if(aboutHSection.children[i].classList.contains("xyz-in")){
          aboutHSection.children[i].classList.add("xyz-out");
          aboutHSection.children[i].classList.remove("xyz-in");
        }
      }
      for(var i = 0; i < aboutCSection.children.length; i++){
        if(aboutCSection.children[i].classList.contains("xyz-in")){
          aboutCSection.children[i].classList.add("xyz-out");
          aboutCSection.children[i].classList.remove("xyz-in");
        }
      }
    }
  }

  handleScroll = () => {
    this.handleServicesContainerAnimation();
    this.handleAboutContainerAnimation();
    
  };

  componentDidMount() {
    var tawk = new TawkTo("61e9f3b4f7cf527e84d33d71", "1fpstvv3u");
    tawk.showWidget();
    window.addEventListener("scroll", this.handleScroll);
  }
  render() {
    return (
      <div className="App relative ">
        <Navigation className="fixed" />
        <HeaderCarousel />
        <M_Services />
        <About />
        <Testimonials />
        <Partner />
        <ContactUs />
        <Footer />
      </div>
    );
  }
}

export default App;
