import React from "react";
const About = () => {
  return (
    <div id="about" className="about-container flex flex-col items-center bg-[#C4C4C4] bg-opacity-30 py-14">
      <div id="about-header" xyz="up stagger fade" className="flex flex-col items-center ">
        <div className="relative xyz-out ">
          <h2 className=" font-Rubik text-xl opacity-90">About the Company</h2>
          <span className="border-b-2 absolute px-10 py-1 inset-x-24 inset-y-2 border-black"></span>
        </div>
        <p className="xyz-out font-Roboto mt-2 text-sm text-center tracking-wider font-medium opacity-95">
          A Company turning ideas into innovative technology solutions
        </p>
      </div>

      <div xyz="up front stagger fade-100%" id="about-content" className="flex flex-col lg:flex-row mt-4 lg:space-x-4 xl:space-x-8">
        <div className="lg:max-w-3xl w-full relative xyz-out">
          <div className="absolute ml-1 w-[96%] md:ml-2 lg:ml-0 flex items-center h-[60px] rounded-sm bg-opacity-70 from-[#30302F] to-transparent bg-gradient-to-tr inset-y-0 mt-auto mb-auto">
            <span className=" ml-4 font-Roboto font-Normal tracking-wide text-white text-[14px] inline-block text-center">
              Spearheading Transformation Towards Dignity
            </span>
          </div>
          <img
            src={require("../../assets/about-image.png")}
            alt="about-img"
            className=" w-full object-cover object-center h-36 lg:h-full"
          />
        </div>
        <div className="xyz-out px-4 lg:max-w-xl mt-6 lg:mt-0 font-Roboto flex flex-col items-center justify-center">
          <p className=" text-justify font-medium opacity-95 text-sm md:text-base leading-7">
            M-PAY enables SME merchants in emerging markets to accept mobile
            money as a form of payment, in strategic partnership with Safaricom
            M-PESA and The Cooperative Bank Of Kenya, through an aggregating
            platform.
          </p>
          <div className="flex space-x-7 items-center mt-5">
            <span className="h-24 border-[#C4C4C4] border-l-[6px]"></span>
            <p className="text-justify text-lg">
              “As a Small Medium Enterprise (SME) enabler Merchants grow their
              revenue by accessing more working capital loans Merchants reduce
              cash management and operational costs Merchants increase their
              profits.”
            </p>
          </div>
          <p className="text-justify opacity-90 text-sm md:text-base mt-5 font-medium leading-7">
            Our goal is to transform the lives of the people we serve towards
            achieving their full potential delivering financial independence.
          </p>
        </div>
      </div>
    </div>
  );
};
export default About;
