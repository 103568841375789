import React from "react";
const Partner = () => {
    return(
        <div id="partner" className="flex overflow-x-auto px-4  items-center justify-start md:justify-center space-x-16 py-10">
            <img className=" object-cover" src={require("../../assets/safcom.png")} alt="safaricom"/>
            <img className=" object-cover aspect-auto" src={require("../../assets/weza.png")} alt="weza"/>
            <img className=" object-cover" src={require("../../assets/coop-bank-logo.png")} alt="Co-operative bank"/>
            <img className=" object-cover" src={require("../../assets/pioneer.png")} alt="PioneerFSA"/>
            <img className=" object-cover" src={require("../../assets/empire-logo.png")} alt="weza"/>
        </div>
    );
}
export default Partner;