import React from "react";
import "./header-carousel.component.css";
import { Carousel} from "antd";
import { ReactComponent as NextArrow } from "../../assets/icons8_forward.svg";
import { ReactComponent as BackArrow } from "../../assets/icons8_back.svg";
import { Button } from "antd";


class HeaderCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      matches: true,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  updateDimensions = (e) => {
    if (window.innerWidth < 768) {
      this.setState({ matches: true });
    } else if (window.innerWidth > 768) {
      this.setState({ matches: false });
    }
  };

  render() {
    return (
      <div id="home" className=" pt-16">
        <Carousel
          className={
            this.state.matches
              ?"header-carousel mobile-carousel": "header-carousel"

          }
          autoplay
          autoplaySpeed={5000}
          arrows={true}
          pauseOnHover={false}
          pauseOnFocus={false}
          nextArrow={<NextArrow />}
          prevArrow={<BackArrow />}
          dots={true}
          fade={true}
          
          beforeChange={(cs,ns) => {
         
         
          }}
          afterChange={(current) => {
            var slickDotbuttons = document
              .getElementsByClassName("slick-dots")[0]
              .getElementsByTagName("button").length;

            this.setState({ currentSlide: current });
            if (current === 1 || current === 2) {
              document
                .getElementsByClassName("slick-prev")[0]
                .getElementsByTagName("path")[0].style.fill = "#fff";
              document
                .getElementsByClassName("slick-next")[0]
                .getElementsByTagName("path")[0].style.fill = "#fff";

              for (var i = 0; i < slickDotbuttons; i++) {
                document
                  .getElementsByClassName("slick-dots")[0]
                  .getElementsByTagName("button")[i].style.backgroundColor =
                  "#fff";
              }
            } else {
              document
                .getElementsByClassName("slick-prev")[0]
                .getElementsByTagName("path")[0].style.fill = "#222222";
              document
                .getElementsByClassName("slick-next")[0]
                .getElementsByTagName("path")[0].style.fill = "#222222";

              for (var i = 0; i < slickDotbuttons; i++) {
                document
                  .getElementsByClassName("slick-dots")[0]
                  .getElementsByTagName("button")[i].style.backgroundColor =
                  "#000000";
              }
            }
            
            if (current === 0) {
              var section = document.getElementById("slide-1-content");
              for (var i = 0; i < section.children.length; i++) {
                section.children[i].classList.add("xyz-in");
                section.children[i].classList.remove("xyz-out");
              }
             
              setTimeout(() => {
                for (var i = 0; i < section.children.length; i++) {
                  section.children[i].classList.remove("xyz-in");
                  section.children[i].classList.add("xyz-out");
                }
              }, 4000);
            } else if (current === 1) {
              var section = document.getElementById("slide-2-content");
              for (var i = 0; i < section.children.length; i++) {
                section.children[i].classList.add("xyz-in");
                section.children[i].classList.remove("xyz-out");
              }
              setTimeout(() => {
                for (var i = 0; i < section.children.length; i++) {
                  section.children[i].classList.remove("xyz-in");
                  section.children[i].classList.add("xyz-out");
                }
              }, 4000);
            } else if (current === 2) {
              var section = document.getElementById("slide-3-content");
              for (var i = 0; i < section.children.length; i++) {
                section.children[i].classList.add("xyz-in");
                section.children[i].classList.remove("xyz-out");
              }
              setTimeout(() => {
                for (var i = 0; i < section.children.length; i++) {
                  section.children[i].classList.remove("xyz-in");
                  section.children[i].classList.add("xyz-out");
                }
              }, 4000);
            }
          }}
        >
          <div className="relative slide1" id="slide1">
            <img
              src={require(`../../assets/dots.png`)}
              className="object-cover absolute w-52 md:w-80"
              alt="Dotted"
            />
            <div className="mt-14 md:mt-0 flex justify-between flex-col md:h-[410px] items-center md:flex-row  md:px-12 lg:px-24">
              <div id="slide-1-content" xyz="up front stagger fade-100%" className=" flex flex-col items-start font-Roboto relative max-w-xs sm:max-w-sm lg:max-w-xl">
                <div>
                <h2 className="xyz-in text-[9px] lg:text-sm font-bold opacity-60 font-Poppins">
                  M-PAY MERCHANT FINANCING SOLUTION FOR SME's
                </h2>
                </div> 
                <div>      
                <p className="xyz-in mt-3 md:mt-6 font-medium opacity-80 tracking-wide text-lg md:text-xl lg:text-2xl leading-10">
                 Get a Lipa Na M-PESA till today at a zero cost and start accepting cashless payments. 
                </p>
                </div>
                <div className=" w-60 self-center">
                  <img
                    src={require(`../../assets/mp10.png`)}
                    className="xyz-in md:hidden block object-cover w-full h-full object-center"
                  />
                </div>
                
                <Button onClick={()=>
                window.open("https://register.m-pay.co.ke/?r=lnm ")}
                  className="xyz-in w-full mt-7 md:w-64 text-sm bg-slate-900 shadow-lg shadow-teal-500/50"
                  type="primary"
                  data-modal-toggle="default-modal"
                >
                  Apply Lipa Na Mpesa Now
                </Button>
              </div>
              <div className=" md:w-[350px]  lg:w-[450px]">
              <img
                src={require(`../../assets/mp10.png`)}
                className=" hidden md:block object-cover w-96 object-center"
              />
              </div>
             
            </div>
          </div>
          <div className="relative flex items-center [height:610px] md:[height:410px] " id="slide2">
            <img
              src={require(`../../assets/dots-white.png`)}
              className="object-cover self-start h-auto absolute w-80 z-10"
              alt="Dotted"
            />
            <img 
              src={require(`../../assets/second-image0.png`)}
              className="object-cover object-top absolute w-full h-full"
              alt="business-people"
            />
            <div className="w-full h-full absolute bg-[#122F5A] opacity-60" />

            <div id="slide-2-content" xyz="up front stagger fade-100%" className=" flex flex-col bg-white/20 justify-between mt-4 font-Roboto relative mx-8 lg:mx-24 rounded-md py-14 px-10 lg:px-28 max-w-md  md:max-w-xl lg:max-w-4xl ">
               <div className="xyz-out">
                <span className="text-sm text-white font-bold opacity-80 font-Poppins">
                  M-PAY MERCHANT FINANCING SOLUTION FOR SME's
                </span>
              </div>
                <p className="xyz-out mt-8 font-Roboto text-white font-medium text-lg lg:text-2xl leading-8">
                We offer Working Capital Loans, Auto logbook Loans, Mobile Cash Advances.
                No guarantors required.</p>
              
                <Button onClick={()=>
                window.open("https://loans.m-pay.co.ke/")}
                  className="xyz-out w-full border-3 shadow-lg shadow-red-500/50 border-white   mt-2 md:w-64 text-sm bg-transparent"
                  type="primary"
                  data-modal-toggle="default-modal"
                >
                  Apply a Loan Now
                </Button>
              </div>
          </div>
          <div className="relative flex items-center [height:610px] md:[height:410px]" id="slide3">
            <img
              src={require(`../../assets/dots-white.png`)}
              className="object-cover self-start h-auto absolute w-80 z-10"
              alt="Dotted"
            />
            <img
              src={require(`../../assets/third-image0.png`)}
              className="object-cover object-center absolute w-full h-full"
              alt="business-people"
            />
           <div className="w-full h-full absolute bg-[#122F5A] opacity-70" />

           <div id="slide-3-content" xyz="up front stagger fade-100%" className="flex flex-col justify-between mt-4 font-Roboto relative mx-8 lg:mx-24 rounded-md py-14 px-10 lg:px-28 max-w-md  md:max-w-xl lg:max-w-4xl ">
                <div className="xyz-out">
                <h2 className="text-sm text-white font-bold opacity-70 font-Poppins">
                  M-PAY MERCHANT FINANCING SOLUTION FOR SME's
                </h2>
                </div>
               
                <p className="xyz-out mt-8 font-Roboto text-white font-medium text-lg lg:text-2xl leading-8">
                  Boost your business stock with a merchant loan at friendly
                  cost, no guarantors needed.
                </p>
              
                <Button onClick={()=>
                  window.open("https://loans.m-pay.co.ke/")}
                  className=" xyz-out w-full border-3 shadow-lg shadow-blue-500/30 border-white   mt-2 md:w-64 text-sm bg-transparent"
                  type="primary"
                  data-modal-toggle="default-modal"
                >
                 Apply For Loan
                </Button>
              </div>
          </div>
        </Carousel>

        <div
          id="default-modal"
          aria-hidden="true"
          className="hidden overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center h-modal md:h-full md:inset-0"
        >
          <div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
                  Terms of Service
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div className="p-6 space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  With less than a month to go before the European Union enacts
                  new consumer privacy laws for its citizens, companies around
                  the world are updating their terms of service agreements to
                  comply.
                </p>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  The European Union’s General Data Protection Regulation
                  (G.D.P.R.) goes into effect on May 25 and is meant to ensure a
                  common set of data rights in the European Union. It requires
                  organizations to notify users as soon as possible of high-risk
                  data breaches that could personally affect them.
                </p>
              </div>

              <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  data-modal-toggle="default-modal"
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  I accept
                </button>
                <button
                  data-modal-toggle="default-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderCarousel;
