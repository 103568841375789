import React from "react";
const M_Services = () => {
    return(
        <div id="services" className="flex flex-col items-center bg-[#C4C4C4] bg-opacity-10 py-2 md:py-8 ">
            <div className="m-services-header flex flex-col items-center relative px-14">
                <div className="relative">
                    <h2 className="font-Rubik text-2xl">What We Do</h2>
                    <span className="border-b-2 absolute px-10 py-1 inset-x-12 inset-y-2 border-black"></span>
                </div>
                <p className=" font-Roboto text-sm text-center tracking-wider font-medium opacity-95">We enable merchants to grow their businesses by accessing these services</p>
            </div>
            <div id="s-container" xyz="fade small stagger" className="m-services-container mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
                <div className="xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/pay-wallet.png")} alt="pay-wallet" className=" w-12"/>
                    <h3 className="font-Rubik mt-3 text-base">Payment Processsing</h3>
                    <span className="font-Roboto text-center text-sm font-medium opacity-90 tracking-wide">We enable SME merchants in emerging markets to use mobile money as a form of payment through our aggregating platform.</span>
                </div>

                <div className="xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/loans.png")} alt="pay-wallet" className=" w-10"/>
                    <h3 className="font-Rubik mt-3 text-base">Business Loans</h3>
                    <span className="font-Roboto text-center font-medium opacity-90 tracking-wide"> We offer Working Capital Loans, Auto logbook Loans, Mobile Cash Advances.</span>
                </div>

                <div className="xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/app.png")} alt="pay-wallet" className=" w-10"/>
                    <h3 className="font-Rubik mt-3 text-base">M-PAY App</h3>
                    <span className="font-Roboto text-center font-medium opacity-90 tracking-wide">Our M-Pay App offers funds collection, transaction processing, and financing of loans to SMEs.</span>
                </div>

                <div className=" xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/csupport.png")} alt="pay-wallet" className=" w-8"/>
                    <h3 className="font-Rubik mt-3 text-base">Customer Support</h3>
                    <span className="font-Roboto text-center font-medium opacity-90 tracking-wide">Our talented team members are skilled at working 24/7 with customers in getting the most out of our products and resolving any issues or problems encountered.</span>
                </div>

                <div className=" xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/API.png")} alt="pay-wallet" className=" w-8"/>
                    <h3 className="font-Rubik mt-3 text-base">API Intergration</h3>
                    <span className="font-Roboto text-center font-medium opacity-90 tracking-wide">We have Seamless Integration of our C2B API Across Platforms to harness M-Pay data and feature sharing in your applications.</span>
                </div>

                <div className="xyz-out m-services-card flex flex-col items-center max-w-xs shadow-lg py-8 px-6">
                    <img src={require("../../assets/pay-wallet.png")} alt="pay-wallet" className=" w-12"/>
                    <h3 className="font-Rubik mt-3 text-base">Lipa Na Mpesa Services</h3>
                    <span className="font-Roboto text-center font-medium opacity-90 tracking-wide">We enable businesses to acquire Till and Paybill Numbers at zero cost. Get a Lipa Na M-PESA till today and start accepting cashless payments.</span>
                </div>
            </div>
        </div>
    );
}
export default M_Services;