import React from "react";
import { Carousel } from "antd";
import "./testimonials.component.css";
import { ReactComponent as NextArrow } from "../../assets/icons8_forward.svg";
import { ReactComponent as BackArrow } from "../../assets/icons8_back.svg";

const Testimonials = () => {
  return (
    <div id="testimonials">
      <Carousel className="partner-carousel" autoplay effect="fade" arrows={true} nextArrow={<NextArrow/>} prevArrow={<BackArrow/>}>
        <div className="flex flex-col justify-center items-center py-14 relative ">
          <img
            src={require("../../assets/testimonial-image.png")}
            alt="testimonial"
            className="object-cover object-top w-full h-full absolute"
          />
          <div className=" h-full w-full bg-[#122F5A] opacity-60 absolute"></div>
          <div className="relative">
            <h2 className="font-Rubik text-xl opacity-90 text-white">
              Merchant Testimonials
            </h2>
            <span className="border-b-2 absolute px-10 py-1 inset-x-32 inset-y-2 border-white"></span>
          </div>
          <div className="max-w-3xl flex flex-col items-center relative">
            <p className="font-Rubik text-gray-300 mt-12 text-center tracking-wider text-lg italic">
              “We are growing the revenue by accessing more working capital
              loans and operational costs and increasing our profits.”
            </p>
            <span className="font-Roboto text-gray-200 mt-1 tracking-wider font-medium">
              Corriss Ambani - QuickMart Admin
            </span>
          </div>
        </div>
         <div className="flex flex-col justify-center items-center py-14 relative ">
          <img
            src={require("../../assets/testimonial-image.png")}
            alt="testimonial"
            className="object-cover object-top w-full h-full absolute"
          />
          <div className=" h-full w-full bg-[#122F5A] opacity-60 absolute"></div>
          <div className="relative">
            <h2 className="font-Rubik text-xl opacity-90 text-white">
              Merchant Testimonials
            </h2>
            <span className="border-b-2 absolute px-10 py-1 inset-x-32 inset-y-2 border-white"></span>
          </div>
          <div className="max-w-3xl flex flex-col items-center relative">
            <p className="font-Rubik text-gray-300 mt-12 text-center tracking-wider text-lg italic">
              “We are growing the revenue by accessing more working capital
              loans and operational costs and increasing our profits.”
            </p>
            <span className="font-Roboto text-gray-200 mt-1 tracking-wider font-medium">
              Corriss Ambani - QuickMart Admin
            </span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
export default Testimonials;
